// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-algorithm-algorithm-js": () => import("./../../../src/components/Algorithm/Algorithm.js" /* webpackChunkName: "component---src-components-algorithm-algorithm-js" */),
  "component---src-components-algorithms-algorithms-page-js": () => import("./../../../src/components/Algorithms/AlgorithmsPage.js" /* webpackChunkName: "component---src-components-algorithms-algorithms-page-js" */),
  "component---src-components-algorithms-all-algorithms-page-js": () => import("./../../../src/components/Algorithms/AllAlgorithmsPage.js" /* webpackChunkName: "component---src-components-algorithms-all-algorithms-page-js" */),
  "component---src-components-coin-coin-js": () => import("./../../../src/components/Coin/Coin.js" /* webpackChunkName: "component---src-components-coin-coin-js" */),
  "component---src-components-coins-all-coins-page-js": () => import("./../../../src/components/Coins/AllCoinsPage.js" /* webpackChunkName: "component---src-components-coins-all-coins-page-js" */),
  "component---src-components-coins-coins-page-js": () => import("./../../../src/components/Coins/CoinsPage.js" /* webpackChunkName: "component---src-components-coins-coins-page-js" */),
  "component---src-components-pool-pool-js": () => import("./../../../src/components/Pool/Pool.js" /* webpackChunkName: "component---src-components-pool-pool-js" */),
  "component---src-components-pools-all-pools-page-js": () => import("./../../../src/components/Pools/AllPoolsPage.js" /* webpackChunkName: "component---src-components-pools-all-pools-page-js" */),
  "component---src-components-pools-pools-page-js": () => import("./../../../src/components/Pools/PoolsPage.js" /* webpackChunkName: "component---src-components-pools-pools-page-js" */),
  "component---src-components-search-search-page-js": () => import("./../../../src/components/Search/SearchPage.js" /* webpackChunkName: "component---src-components-search-search-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertise-js": () => import("./../../../src/pages/advertise.js" /* webpackChunkName: "component---src-pages-advertise-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-masternodes-js": () => import("./../../../src/pages/masternodes.js" /* webpackChunkName: "component---src-pages-masternodes-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-verified-js": () => import("./../../../src/pages/verified.js" /* webpackChunkName: "component---src-pages-verified-js" */)
}

